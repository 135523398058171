import request from "@/utils/request";
// 登录方法
export function login(data) {
  return request({
    url: "loginx",
    method: "post",
    data: data,
  });
}
// 验证码
export function getCaptchaImage() {
  return request({
    url: "captchaImage",
    method: "get",
  });
}
// 手机登录
export function loginByPhone(data) {
  return request({
    url: `loginByPhone?phoneNumber=${data.phone}&verifyCode=${data.verifyCode}`,
    method: "post",
  });
}
// 忘记密码重置密码
export function resetPassword(data) {
  return request({
    url: "resetPassword/withUrbanBuilding",
    method: "post",
    data,
  });

}
//获取用户系统信息
export function getCurrentUser() {
  return request({
    url: "getCurrentUser",
    method: "get",
  });
}
//获取用户信息
export function getInfo() {
  return request({
    url: "getInfo",
    method: "get",
  });
}
//修改用户信息
export function updateUserInfo(data) {
  return request({
    url: "updateUserInfo",
    method: "post",
    data,
  });
}
export function weixinLogin(data) {
  return request({
    url: "sys/wx/login",
    method: "post",
    data,
  });
}
//产品中心分组
export function homePageMenu(data) {
  let url = 'system/user/homePageMenu'
  if (data) {
    url = url + '?' +  data
  }
  return request({
    url: url,
    method: "get",
  });
}